<template>
  <section class="content-wrapper" style="margin-left: 0 !important">
    <div class="container">
      <section>
        <!-- BEGIN SPINNER -->
        <div v-if="!termsOfService || !termsOfService.content">
          <div class="row">
            <div class="col-md-12">
              <Spin class="spinner" />
            </div>
          </div>
        </div>
        <!-- END SPINNER -->
        <!-- BEGIN TERMS -->
        <div
          class="tab-pane active box box-body"
          role="tabpanel"
          aria-labelledby=""
          v-if="termsOfService && termsOfService.content"
        >
          <p>
            <i
              >{{ $t("updated_at") }}:
              {{ dateTimeFormat(termsOfService.updated_at) }}</i
            >
          </p>

          <div v-html="termsOfService.content"></div>

          <div class="text-center" v-if="isOwner">
            <hr />

            <p>
              <label for="terms" class="terms no-select clicable text-center">
                <input
                  type="checkbox"
                  v-model="accepted"
                  :disabled="termsOfService.accepted_datetime"
                />
                <span @click.stop.prevent="toggleAccepted">
                  {{ $t("i_accept_the") }} {{ $t("terms_of_use") }} &
                  {{ $t("privacy_policy") }}
                </span>
              </label>
            </p>

            <p>
              <router-link
                to="/dashboard/search"
                :class="
                  termsOfService.accepted_datetime
                    ? 'btn btn-primary'
                    : 'btn btn-default'
                "
              >
                <i
                  class="fa fa-home"
                  style="margin-right: 5px; font-weight: 600"
                ></i>
                {{ $t("back") }}
              </router-link>
              <button
                v-if="!busy && termsOfService.accepted_datetime"
                type="button"
                class="btn btn-default"
                style="margin-left: 10%"
                disabled
              >
                <i class="fa fa-check"></i>
                {{ $t("updated_at") }}
                {{ dateTimeFormat(termsOfService.accepted_datetime) }}
              </button>
              <button
                v-else
                type="button"
                class="btn btn-primary"
                style="margin-left: 10%"
                @click="save"
                :disabled="
                  busy || !accepted || termsOfService.accepted_datetime
                "
              >
                <i class="fa fa-check"></i>
                {{ $t("confirm") }}
              </button>
            </p>
          </div>
        </div>
        <!-- END TERMS -->
      </section>
    </div>
  </section>
</template>

<script>
import AuthService from "@/services/auth";
import TermsOfServiceService from "@/services/terms-of-service.js";
import Spin from "@/components/spin";

export default {
  name: "TermsOfService",
  data() {
    return {
      busy: false,
      accepted: false,
      termsOfService: null
    };
  },
  components: {
    // PanelHeaderEquipmentList,
    Spin
  },
  computed: {
    contract() {
      return this.$store.getters["user/contract"] || null;
    },
    isOwner() {
      return this.$store.getters["user/loggedUser"]?.is_root ?? false;
    }
  },
  methods: {
    toggleAccepted() {
      if (this.busy || this.termsOfService?.accepted_datetime) return;
      this.accepted = !this.accepted;
    },
    dateTimeFormat(str_date) {
      return moment(str_date).format('DD/MM/YYYY HH:mm:ss');
    },
    showToasted(msg) {
      let options = {
        singleton: true,
        type: "success",
        icon: "fa-check",
        iconPack: "fontawesome",
        position: "",
        duration: ""
      };
      if (this.$root.config.alert && "success" in this.$root.config.alert) {
        Object.assign(options, this.$root.config.alert);
      }
      options.position = (options["success"].options.position || "")
        .replace(/(1\-|\-1)/g, "")
        .toLowerCase();
      options.duration = options["success"].options.duration;
      this.$toasted.show(msg, options);
    },
    fetchTermsOfService() {
      this.busy = true;
      if (this?.termsOfService?.id) return;
      this.service.get(this.contract && { contract_id: this.contract.id }).then((ret) => {
        this.busy = false;
        this.$set(this, "termsOfService", ret || null);
        this.accepted = this?.termsOfService?.accepted_datetime ? true : false;
      });
    },
    save() {
      if (!this.accepted && this.termsOfService?.accepted_datetime) return;
      this.busy = true;
      this.service.save({ contract_id: this.contract.id }).then((ret) => {
        this.busy = false;
        this.$set(this, "termsOfService", ret || null);
        if (ret)
          this.$store.dispatch("user/setTermsAcceptanceDatetime", ret?.accepted_datetime ?? null);
        this.accepted = this?.termsOfService?.accepted_datetime ? true : false;
        this.showToasted(this.$t("saved"));
      });
    }
  },
  watch: {
    contract: {
      handler(n) {
        if (this.termsOfService || (this.contractRequired && !n)) return;
        this.fetchTermsOfService();
      },
      immediate: true,
      deep: true
    }
  },
  beforeCreate() {
    this.service = new TermsOfServiceService();
    let auth = new AuthService();
    if (auth.access_token()) {
      this.contractRequired = true;
      this.$store
        .dispatch("user/login", { keep_alive: auth.keepAlive() })
        .catch(() => {
          this.$router.push("/");
        });
    }
  },
  beforeDestroy() {
    this.service = null;
  }
}
</script>

<style scoped>
.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}
.content-wrapper {
  min-height: 100dvh;
}
.container {
  padding: 10px;
  border-radius: 5px;
}
.clicable {
  cursor: pointer;
}
</style>